import axios from 'axios'
import Stripe from 'stripe'
import { SUBSCRIPTION_TYPES } from '../utils/consts'
import { SubscriptionType as SubType } from '@prisma/client'

export const getSubscriptionId = async (sessionId: string) => {
  const data = await axios.post(
    `/api/stripe/retrieveSubscription?sessionID=${sessionId}`
  )
  return data.data.subscription_id as string
}

export const getPriceData = async () => {
  const data = await axios.get('/api/stripe/getProducts')
  const apiUrl = '/api/stripe/getPrice?priceId='

  const pricesData = data.data.data

  const result: any = {
    [SUBSCRIPTION_TYPES.STARTER.id]: {
      annual: null,
      annual_id: null,
      monthly: null,
      monthly_id: null,
    },
    [SUBSCRIPTION_TYPES.BASIC.id]: {
      annual: null,
      annual_id: null,
      monthly: null,
      monthly_id: null,
    },
    [SUBSCRIPTION_TYPES.PREMIUM.id]: {
      annual: null,
      annual_id: null,
      monthly: null,
      monthly_id: null,
    },
  }
  await Promise.all(
    pricesData.map(async (data: any) => {
      if (data.active) {
        if (data.id === SUBSCRIPTION_TYPES.PREMIUM.prodId.annual) {
          const priceData = await axios.get(apiUrl + data['default_price'])

          const price = priceData.data['unit_amount']
          result[SUBSCRIPTION_TYPES.PREMIUM.id].annual =
            Math.round((price / 12 / 100) * 100) / 100
          result[SUBSCRIPTION_TYPES.PREMIUM.id].annual_id = priceData.data['id']
        }
        if (data.id === SUBSCRIPTION_TYPES.PREMIUM.prodId.monthly) {
          const priceData = await axios.get(apiUrl + data['default_price'])

          const price = priceData.data['unit_amount']
          result[SUBSCRIPTION_TYPES.PREMIUM.id].monthly = price / 100
          result[SUBSCRIPTION_TYPES.PREMIUM.id].monthly_id =
            priceData.data['id']
        }
        if (data.id === SUBSCRIPTION_TYPES.STARTER.prodId.annual) {
          const priceData = await axios.get(apiUrl + data['default_price'])

          const price = priceData.data['unit_amount']
          result[SUBSCRIPTION_TYPES.STARTER.id].annual =
            Math.round((price / 12 / 100) * 100) / 100
          result[SUBSCRIPTION_TYPES.STARTER.id].annual_id = priceData.data['id']
        }
        if (data.id === SUBSCRIPTION_TYPES.STARTER.prodId.monthly) {
          const priceData = await axios.get(apiUrl + data['default_price'])

          const price = priceData.data['unit_amount']
          result[SUBSCRIPTION_TYPES.STARTER.id].monthly = price / 100
          result[SUBSCRIPTION_TYPES.STARTER.id].monthly_id =
            priceData.data['id']
        }
        if (data.id === SUBSCRIPTION_TYPES.BASIC.prodId.annual) {
          const priceData = await axios.get(apiUrl + data['default_price'])

          const price = priceData.data['unit_amount']
          result[SUBSCRIPTION_TYPES.BASIC.id].annual =
            Math.round((price / 12 / 100) * 100) / 100
          result[SUBSCRIPTION_TYPES.BASIC.id].annual_id = priceData.data['id']
        }
        if (data.id === SUBSCRIPTION_TYPES.BASIC.prodId.monthly) {
          const priceData = await axios.get(apiUrl + data['default_price'])

          const price = priceData.data['unit_amount']
          result[SUBSCRIPTION_TYPES.BASIC.id].monthly = price / 100
          result[SUBSCRIPTION_TYPES.BASIC.id].monthly_id = priceData.data['id']
        }
      }
    })
  )
  return result
}

export const getInvoiceData = async (
  subscriptionId: string,
  subscriptionPlan: SubType,
  isAnnual: boolean
) => {
  const data = await axios.get(
    '/api/stripe/subscription/retrieveUpcomingInvoice?subID=' +
      subscriptionId +
      '&subscriptionPlan=' +
      subscriptionPlan +
      '&isAnnual=' +
      isAnnual
  )
  const invoice = data.data.invoice as Stripe.Invoice
  return invoice
}

export const getNextPayment = async (
  subscriptionId: string,
  subscriptionType: SubType,
  isAnnual: boolean
) => {
  const { next_payment_attempt } = await getInvoiceData(
    subscriptionId,
    subscriptionType,
    isAnnual
  )
  return next_payment_attempt ? next_payment_attempt : 0
}

export const calculateSubscriptionChangeFee = (invoice: Stripe.Invoice) => {
  const { data } = invoice.lines
  if (data.length > 1) {
    return data[0].amount + data[1].amount + invoice.starting_balance
  }
  if (invoice.amount_remaining > 0) {
    return invoice.amount_remaining
  }
  if (invoice.ending_balance && invoice.ending_balance < 0) {
    return invoice.ending_balance
  }
  return 0
}

export const trySubscriptionChange = async (
  subscriptionId: string,
  subscriptionPlan: SubType,
  isAnnual: boolean
): Promise<boolean> => {
  try {
    const { status } = await axios.put(
      `/api/stripe/subscription?subscriptionID=${subscriptionId}&priceType=${subscriptionPlan}&annual=${isAnnual}`
    )
    if (status === 200) {
      return true
    }
    return false
  } catch (err) {
    console.error(err)
    return false
  }
}

export const getCouponCodes = async (
  coupon: string
): Promise<Stripe.PromotionCode[]> => {
  const { data } = await axios.get(`/api/stripe/coupon/${coupon}`)
  return data.promotion_code.data
}

export const addCouponCode = async (
  subscriptionId: string,
  couponId: string
) => {
  try {
    await axios.post(
      `/api/stripe/coupon?subscriptionID=${subscriptionId}&couponID=${couponId}`
    )
  } catch (err) {
    throw new Error('Invalid or already used Coupon!')
  }
}

export const createSubscripctions = async (
  customerId: string,
  priceId: string,
  coupon: string,
  referral: string
) => {
  const { data } = await axios.post(
    `/api/stripe/subscription?customerId=${customerId}&priceId=${priceId}&couponId=${coupon}&referral=${referral}`
  )
  return data
}

export const cancelSubscription = async (subscriptionId: string) => {
  const { data } = await axios.delete(
    `/api/stripe/subscription?subscriptionId=${subscriptionId}`
  )
  return data
}

export const retrieveSubscriptionById = async (subscriptionID: string) => {
  const {
    data: { subscription },
  } = await axios.get(
    `/api/stripe/subscription?subscriptionID=${subscriptionID}`
  )
  return subscription
}

export const addNewCard = async (
  stripeID: string,
  cardNumber: string,
  exp_month: string,
  exp_year: string,
  cvc: string
): Promise<string> => {
  const {
    data: { cardId },
  } = await axios
    .post(
      `/api/stripe/customer/card?customerID=${stripeID}&cardNumber=${cardNumber}&exp_month=${exp_month}&exp_year=${exp_year}&cvc=${cvc}`
    )
    .catch(function (error) {
      return Promise.reject(error.response.data.message)
    })
  return cardId
}

export const updateUserCard = async (stripeID: string, cardID: string) => {
  await axios.put(
    `/api/stripe/customer/card?customerID=${stripeID}&cardID=${cardID}`
  )
}

export const retrieveCurrentCard = async (customerID: string) => {
  const {
    data: { customer },
  } = await axios.get(`/api/stripe/customer?customerID=${customerID}`)
  if (customer.default_source) {
    const {
      data: { card },
    } = await axios.get(
      `/api/stripe/customer/card?customerID=${customerID}&cardID=${customer.default_source}`
    )
    return card
  }
  return ''
}

export const retrieveCustomer = async (customerID: string) => {
  const {
    data: { customer },
  } = await axios.get(`/api/stripe/customer?customerID=${customerID}`)
  return customer
}

export const retrievePaymentMethod = async (paymentID: string) => {
  const {
    data: { paymentMethod },
  } = await axios.get(
    `/api/stripe/customer/retrievePaymentMethod?paymentID=${paymentID}`
  )
  return paymentMethod
}
